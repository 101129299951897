import { authorizedApi, authorizedArbitrager } from "./services";

export const fetcher = (path: string | any[]) => {
	if (Array.isArray(path)) {
		let [newPath, config] = path;
		const isArbitrager = newPath.includes("/arbitrager");
		let api = authorizedApi;
		if (isArbitrager) {
			newPath = newPath.replace("/arbitrager", "");
			api = authorizedArbitrager;
		}

		if (config.method?.toLowerCase() === "options") {
			return api.options(newPath, config).then((res) => res.data);
		}
		return api.get(newPath, config).then((res) => res.data);
	}

	let newPath = path;
	const isArbitrager = path.includes("/arbitrager");
	let api = authorizedApi;
	if (isArbitrager) {
		newPath = path.replace("/arbitrager", "");
		api = authorizedArbitrager;
	}
	return api.get(newPath).then((res) => res.data);
};
