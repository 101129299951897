import { dateFormats } from "@app/constants/date-formats";
import type {
	ArbitragerSpreads,
	ChartType,
	DataSet,
	SpreadResponse,
} from "@app/entities";
import { useMediaQuery } from "@app/hooks/use-media-query";
import type { RootState } from "@app/redux";
import {
	type FormatNumberOptions,
	formatNumber,
} from "@app/utils/format-number";
import { snapNumber } from "@app/utils/snap-number";
import type { TooltipItem } from "chart.js";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import type { Properties } from "./properties";
import { SpreadGraphCardView } from "./spread-graph-card-view";
import { useHistorySpread } from "./use-history-spread";

interface SpreadGraphCardState {
	arbitrageBreakdown: ArbitragerSpreads;
	chartData?: DataSet;
	chartLabels?: string[];
	chartLabelsFull?: string[];
	intervalIndex: number;
}

const now = new Date();

const getBreakdownValue = (
	arbitrageBreakdown: ArbitragerSpreads,
	value: SpreadResponse,
) => {
	switch (arbitrageBreakdown) {
		case "EXCHANGE_RATE": {
			return value.exchangeRate;
		}
		case "LOCAL_PRICE": {
			return value.localPrice;
		}
		case "OFFSHORE_PRICE": {
			return value.offshorePrice;
		}
		default: {
			return value.spread;
		}
	}
};

export const SpreadGraphCard = (props: Properties) => {
	const isMobile = useMediaQuery();

	const { currentPeriod, periodSettings } = useSelector(
		(rootState: RootState) => rootState.arbitrager,
	);

	const { historySpread, isLoading: historySpreadLoading } = useHistorySpread(
		currentPeriod?.days,
	);

	const intervalIndex = useMemo(() => {
		return periodSettings
			? Math.max(
					periodSettings?.marketChart.periods.findIndex(
						(value) => (value.days ?? 0) === (currentPeriod?.days ?? 0),
					),
					0,
				)
			: 0;
	}, [currentPeriod, periodSettings]);

	const defaultState: SpreadGraphCardState = {
		arbitrageBreakdown: "SPREAD",
		intervalIndex: intervalIndex,
	};

	const [state, setState] = useState<SpreadGraphCardState>(defaultState);

	const [canChangePeriod, setCanChangePeriod] = useState(false);

	const mapBreakdown = () => {
		const chartData: DataSet = { data: [], label: "" };

		chartData.data = historySpread.map((x) =>
			getBreakdownValue(state.arbitrageBreakdown, x),
		);
		chartData.label = "";

		const timezoneOffset = now.getTimezoneOffset();

		const chartLabels = historySpread.map((x) => {
			const dataDate = moment(x.datetime, dateFormats.iso8601withTime).subtract(
				timezoneOffset,
				"minutes",
			);

			if (currentPeriod?.days === 1) {
				return dataDate
					.clone()
					.set("hours", snapNumber(dataDate.hours(), 1))
					.set("minutes", snapNumber(dataDate.minutes(), 60))
					.format(dateFormats.time24HoursMinutes);
			}
			return dataDate.format(dateFormats.iso8601withTime);
		});

		const chartLabelsFull = historySpread.map((x) => {
			const dataDate = moment(x.datetime, dateFormats.iso8601withTime);
			return dataDate.format(dateFormats.iso8601withTime);
		});

		setState({
			...state,
			chartData: chartData,
			chartLabels: chartLabels,
			chartLabelsFull: chartLabelsFull,
		});
	};

	const onChange = (period: string) => {
		setState({
			...state,
			intervalIndex: +period,
		});
	};

	const onChangeBreakdown = (value: string) => {
		const valueAsArbitrager = value as ArbitragerSpreads;
		if (valueAsArbitrager && valueAsArbitrager.length > 0) {
			setState({
				...state,
				arbitrageBreakdown: valueAsArbitrager,
			});
		}
	};

	const onRenderTooltipLabel = (
		tooltip: TooltipItem<ChartType>,
		formatOptions: FormatNumberOptions,
	) => {
		return state.chartData
			? `${formatNumber(tooltip.parsed.y, formatOptions ?? undefined)}`
			: `${tooltip.dataset.label ?? ""}: ${tooltip.parsed.y}`;
	};

	const onRenderTooltipTitle = (tooltips: TooltipItem<ChartType>[]) => {
		const data = state.chartLabelsFull;
		return data && data.length > 0 && tooltips.length > 0
			? moment(
					data[Math.max(0, tooltips[0].dataIndex)],
					dateFormats.iso8601withTime,
				)
					.subtract(now.getTimezoneOffset(), "minutes")
					.format(dateFormats.iso8601withTime)
			: "Date";
	};

	useEffect(() => {
		if (canChangePeriod) {
			if (props.onChangePeriod) {
				props.onChangePeriod(
					periodSettings?.marketChart.periods[state.intervalIndex],
				);
			}
		}
	}, [state.intervalIndex]);

	useEffect(() => {
		mapBreakdown();
		const timeout = setTimeout(() => {
			setCanChangePeriod(true);
		}, 500);
		return () => {
			clearTimeout(timeout);
		};
	}, []);

	useEffect(() => {
		mapBreakdown();
	}, [historySpread, state.arbitrageBreakdown, currentPeriod]);

	return (
		<SpreadGraphCardView
			{...props}
			breakdown={state.arbitrageBreakdown}
			chartData={state.chartData}
			chartLabels={state.chartLabels}
			chartLabelsFull={state.chartLabelsFull}
			currentPeriod={currentPeriod}
			intervalIndex={state.intervalIndex}
			loading={props.loading || historySpreadLoading || !state.chartData}
			periodSettings={periodSettings}
			onChange={onChange}
			onChangeBreakdown={onChangeBreakdown}
			onRenderTooltipLabel={onRenderTooltipLabel}
			onRenderTooltipTitle={onRenderTooltipTitle}
			isDesktop={!isMobile}
		/>
	);
};
