export const tradeStatusOptions = [
	{
		label: "All (default)",
		value: "all",
	},
	{
		label: "Trade in progress",
		value: "trade_in_progress",
	},
	{
		label: "No trade in progress",
		value: "no_trade_in_progress",
	},
];

export const aitStatusOptions = [
	{
		label: "All (default)",
		value: "all",
	},
	{
		label: "No AIT in progress",
		value: "no_AIT_in_progress",
	},
	{
		label: "AIT pending",
		value: "AIT_pending",
	},
	{
		label: "Trading complete",
		value: "trading_complete",
	},
];
