import React from "react";
import "./custom-loader.css";
import type { Properties } from "./properties";

const CustomLoaderView = React.memo((props: Properties) => {
	const className = [
		"spinner-border spinner-border mr-1",
		`spinner-${props.size}`,
		`spinner-${props.page ? "page" : "contained"}`,
		`spinner-${props.thickness}`,
		props.fast ? "spinner-fast" : "",
		props.className,
	].join(" ");

	const overlayClasses = [
		"spinner-overlay",
		`spinner-${props.theme || "light"}`,
		`spinner-overlay-${props.page ? "page" : "contained"}`,
	].join(" ");

	return (
		<div className={overlayClasses}>
			<span className={className} />
		</div>
	);
});

export { CustomLoaderView };
