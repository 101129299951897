import { allowanceLimit } from "@app/constants/allowance-limit";
import type { TradeStage } from "@app/constants/trade-stage";
import type { Period, SpreadResponse, StatisticsResponse } from "@app/entities";
import {
	useGetClient,
	useGetClientCycles,
	useGetHistorySpread,
	useGetStatistics,
	useRequestWithdrawal,
	useSetCurrentPeriod,
} from "@app/helpers";
import type { RootState } from "@app/redux";
import type { MappedReasons } from "@app/services";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DashboardView } from "./dashboard-view";
import type { Properties } from "./properties";

interface State {
	showDepositModal: boolean;
	showErrors: boolean;
	showAccountSelectionModal: boolean;
	showKrakenModal: boolean;
	showWithdrawModal: boolean;
	showWithdrawSuccessModal: boolean;
	showKrakenSuccessModal: boolean;
	straightThroughProcess: boolean;
	krakenUpdateSuccessMessage?: string[];
	statistics?: StatisticsResponse;
	mappedReasons?: MappedReasons;
}

const Dashboard = () => {
	const [getClientCycles] = useGetClientCycles();
	const [requestWithdrawal] = useRequestWithdrawal();
	const [getHistorySpread] = useGetHistorySpread();
	const [getStatistics] = useGetStatistics();
	const [setCurrentPeriod] = useSetCurrentPeriod();
	const [getClient] = useGetClient();

	const {
		activeClient,
		activeClientError,
		activeClientLoading,
		cycles,
		cyclesError,
		cyclesLoading,
		statementError,
		statementLoading,
		statistics,
		statisticsError,
	} = useSelector((state: RootState) => state.clients);

	const { requestWithdrawalError, requestWithdrawalLoading } = useSelector(
		(state: RootState) => state.withdrawals,
	);

	const {
		currentPeriod,
		periodSettings,
		periodSettingsError,
		historySpread,
		historySpreadError,
	} = useSelector((state: RootState) => state.arbitrager);

	const { profile } = useSelector((state: RootState) => state.auth);

	const [state, setState] = useState<State>({
		showDepositModal: false,
		showErrors: false,
		showAccountSelectionModal: false,
		showKrakenModal: false,
		showWithdrawModal: false,
		showWithdrawSuccessModal: false,
		showKrakenSuccessModal: false,
		straightThroughProcess: false,
		statistics: undefined,
	});

	const getErrors = () => {
		return [
			activeClientError?.join(" "),
			cyclesError?.join(" "),
			historySpreadError?.join(" "),
			periodSettingsError?.join(" "),
			requestWithdrawalError?.join(" "),
			statementError?.join(" "),
			statisticsError?.join(" "),
		].join(" ");
	};

	const fetchHistorySpread = (period?: Period) => {
		if (period) {
			getHistorySpread(
				period?.days,
				(response?: string[] | SpreadResponse[] | undefined) => {
					if (!response || "reasons" in response) {
						setState({ ...state, showErrors: true });
					} else {
						setCurrentPeriod(period);
					}
				},
			);
		}
	};

	const isLoading = () => {
		return (
			activeClientLoading ||
			cyclesLoading ||
			requestWithdrawalLoading ||
			statementLoading
		);
	};

	const submitWithdraw = (
		value: number,
		withdrawAll: boolean,
		bankId: number,
		straightThroughProcess: boolean,
	) => {
		if (activeClient?.onboardingComplete === false) {
			return;
		}

		const newState = state;
		newState.showDepositModal = false;
		newState.straightThroughProcess = straightThroughProcess;

		requestWithdrawal(
			String(value),
			withdrawAll,
			bankId,
			straightThroughProcess,
			(
				success: boolean,
				response?: string[] | undefined,
				mappedReasons?: MappedReasons | undefined,
			) => {
				setState({
					...newState,
					showWithdrawSuccessModal: success,
					mappedReasons: mappedReasons,
				});
			},
		);
	};

	const onChangePeriod = (period?: Period) => {
		if (period) {
			setCurrentPeriod(period);
			fetchHistorySpread(period);
		}
	};

	const onClearErrors = () => {
		setState({ ...state, showErrors: false });
	};

	const onChangeStatistics = (value: string) => {
		if (activeClient) {
			getStatistics(
				activeClient?.id,
				value,
				(response?: StatisticsResponse | string[] | undefined) => {
					if (!response || !("slug" in response)) {
						setState({ ...state, showErrors: true });
					}
				},
			);
		}
	};

	const onMinimumReturnChange = (value: string) => {
		/// TODO: implement redux calls to set client's minimum return
	};

	const onToggleDepositModal = () => {
		if (activeClient?.onboardingComplete === false) {
			return;
		}

		setState({ ...state, showDepositModal: !state.showDepositModal });
	};

	const onToggleAccountSelectionModal = () => {
		if (activeClient?.onboardingComplete === false) {
			return;
		}

		setState({
			...state,
			showAccountSelectionModal: !state.showAccountSelectionModal,
		});
	};

	const onToggleWithdrawSuccessModal = () => {
		setState({
			...state,
			showWithdrawSuccessModal: !state.showWithdrawSuccessModal,
			showAccountSelectionModal: !state.showAccountSelectionModal,
		});
	};

	const onLinkKrakenAccount = () => {
		setState({ ...state, showKrakenModal: true });
	};

	const onCloseKrakenAccountModal = () => {
		setState({ ...state, showKrakenModal: false });
	};

	const onCloseKrakenSuccessModal = () => {
		setState({ ...state, showKrakenSuccessModal: false });
	};

	const onKrakenAccountUpdated = (krakenUpdateSuccessMessage?: string[]) => {
		if (activeClient) getClient(activeClient?.id);

		setState({
			...state,
			showKrakenModal: false,
			showKrakenSuccessModal: true,
			krakenUpdateSuccessMessage,
		});
	};

	useEffect(() => {
		getClientCycles(undefined, undefined, 5);
	}, [activeClient]);

	const activeClientId = activeClient?.id || 0;

	useEffect(() => {
		if (activeClient) {
			let statsSlug = activeClient.defaultStat?.slug;
			if (statistics) {
				statsSlug = statistics.slug;
			}
			if (statsSlug) {
				getStatistics(activeClient.id, statsSlug);
			}
		}
	}, [activeClientId]);

	useEffect(() => {
		setState({ ...state, statistics: statistics });
	}, [statistics]);

	useEffect(() => {
		if (currentPeriod) {
			if (!historySpread || historySpread.length === 0) {
				fetchHistorySpread(currentPeriod);
			}
		}
	}, []);

	const getViewProperties = () => {
		// #region DEFAULT VALUES
		const viewProps: Properties = {
			email: profile?.email || "",
			minimumReturn: 0,
			balance: 0,
			balanceLastUpdated: "",
			balanceLastUpdatedWarning: "",
			allowance: {
				sda: {
					current: 0,
					limit: allowanceLimit.sda,
				},
				fia: {
					current: 0,
					limit: allowanceLimit.fia,
				},
			},
			errors: getErrors(),
			tradeStatus: {
				status: "Deposit Arrived - Awaiting Settlement",
				stage: 1,
				investedAmount: 0,
				netProfit: 0,
			},
			tradeList: cycles?.length > 0 ? cycles : [],
			depositFundsDetails: {
				accountHolder: "XXXXXXXXXX",
				bankName: "XXXXXXXXXX",
				branchCode: "000000",
				accountNumber: "000000000",
				accountType: "XXXXXXXXX",
				rmName: "",
				rmEmail: "",
			},
			clientName: "",
			totalProfit: 0,
			loading: isLoading(),
			showDepositModal: state.showDepositModal,
			showErrors: state.showErrors,
			showAccountSelectionModal: state.showAccountSelectionModal,
			showKrakenModal: state.showKrakenModal,
			showWithdrawSuccessModal: state.showWithdrawSuccessModal,
			straightThroughProcess: state.straightThroughProcess,
			showWithdrawModal: state.showWithdrawModal,
			onChangePeriod: onChangePeriod,
			onChangeStatistics: onChangeStatistics,
			onClearErrors: onClearErrors,
			onMinimumReturnChange: onMinimumReturnChange,
			onSubmitWithdrawal: submitWithdraw,
			onToggleDepositModal: onToggleDepositModal,
			onToggleAccountSelectionModal: onToggleAccountSelectionModal,
			onToggleWithdrawSuccessModal: onToggleWithdrawSuccessModal,
			onLinkKrakenAccount,
			onCloseKrakenAccountModal,
			onCloseKrakenSuccessModal,
			onKrakenAccountUpdated,
			showKrakenSuccessModal: state.showKrakenSuccessModal,
			krakenUpdateSuccessMessage: state.krakenUpdateSuccessMessage,
			mappedReasons: state.mappedReasons,
		};

		if (activeClient) {
			viewProps.activeClient = activeClient;
			viewProps.minimumReturn = activeClient.minimumReturn || 0;
			viewProps.balance = activeClient.fundsAvailable || 0;
			viewProps.clientName = `${activeClient.firstName ?? ""} ${activeClient.lastName ?? ""}`;
			viewProps.totalProfit = activeClient.totalProfit;

			if (activeClient.allowanceAvailable) {
				if (activeClient.allowanceAvailable.fiaDetails) {
					viewProps.allowance.fiaDetails =
						activeClient.allowanceAvailable.fiaDetails;
					viewProps.allowance.fia.current =
						activeClient.allowanceAvailable.fiaDetails.available.amount +
						activeClient.allowanceAvailable.fiaDetails?.pending.amount +
						activeClient.allowanceAvailable.fiaDetails.locked.amount;
				}

				if (activeClient.allowanceAvailable.sdaDetails) {
					viewProps.allowance.sda.current =
						activeClient.allowanceAvailable.sdaDetails.sdaUnused;
					viewProps.allowance.sdaDetails =
						activeClient.allowanceAvailable.sdaDetails;
				}
			}

			if (activeClient.depositBank) {
				viewProps.depositFundsDetails.accountHolder = `${activeClient.firstName[0]} ${activeClient.lastName}`;
				viewProps.depositFundsDetails.bankName = activeClient.depositBank.bank;
				viewProps.depositFundsDetails.branchCode =
					activeClient.depositBank.branchCode;
				viewProps.depositFundsDetails.accountNumber =
					activeClient.depositBank.depositAccountNumber;
				viewProps.depositFundsDetails.accountType =
					activeClient.depositBank.accountType;
			}

			if (activeClient.fundsLastUpdated?.string) {
				viewProps.balanceLastUpdated = activeClient.fundsLastUpdated.string;
			}

			if (activeClient.fundsLastUpdated?.warningMessage) {
				viewProps.balanceLastUpdatedWarning =
					activeClient.fundsLastUpdated.warningMessage;
			}

			if (activeClient.relationshipManager) {
				viewProps.depositFundsDetails.rmName = `${activeClient.relationshipManager.firstName} ${activeClient.relationshipManager.lastName}`;
				viewProps.depositFundsDetails.rmEmail =
					activeClient.relationshipManager.email;
			}

			if (activeClient.tradeStatus)
				viewProps.tradeStatus = {
					status: activeClient.tradeStatus.label,
					stage: (activeClient.tradeStatus.position || 0) as TradeStage,
					investedAmount: activeClient.tradeStatus.amountInvested || 0,
					netProfit: activeClient.tradeStatus.netProfit || 0,
				};
		}

		if (periodSettings) {
			viewProps.periodSettings = periodSettings;
		}

		if (statistics) {
			viewProps.statistics = state.statistics;
		} else if (activeClient?.defaultStat) {
			viewProps.statistics = activeClient.defaultStat;
		}

		return viewProps;
	};

	const viewProps = getViewProperties();

	return <DashboardView {...viewProps} />;
};

export default Dashboard;
