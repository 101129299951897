import * as Dialog from "@radix-ui/react-dialog";
import { useState } from "react";
import { aitStatusOptions, tradeStatusOptions } from "../filter-options";
import { OutlineButton } from "../outline-button";

import { Dropdown } from "@app/controls/dropdown";
import { PrimaryButton } from "../primary-button";
import styles from "./index.module.css";

export const FiltersModal = ({
	onApply,
	activeTradeStatus,
	activeAitStatus,
}: {
	activeTradeStatus: string;
	activeAitStatus: string;
	onApply: (tradeStatus: string, aitStatus: string) => void;
}) => {
	const [tradeStatus, setTradeStatus] = useState(activeTradeStatus);
	const [aitStatus, setAitStatus] = useState(activeAitStatus);
	const [isOpen, setIsOpen] = useState(false);
	const appliedFiltersCount =
		(tradeStatus !== "all" ? 1 : 0) + (aitStatus !== "all" ? 1 : 0);
	return (
		<Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
			<Dialog.Trigger asChild>
				<OutlineButton className={styles.dialogButton}>
					Filters{appliedFiltersCount > 0 ? ` (${appliedFiltersCount})` : ""}
				</OutlineButton>
			</Dialog.Trigger>

			<Dialog.Portal>
				<Dialog.Content className={styles.content}>
					<header className={styles.header}>
						<button
							className={styles.headerAction}
							type="button"
							aria-label="Close"
							onClick={() => setIsOpen(false)}
						>
							<svg
								width="24"
								height="24"
								role="presentation"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M19 12H5M5 12L12 19M5 12L12 5"
									stroke="#D1D1D1"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
						Filter by
						<div className={styles.headerAction} />
					</header>
					<div className={styles.inner}>
						<div>
							<label htmlFor="tradeStatus" className={styles.filterLabel}>
								Trade Status
							</label>
							<Dropdown
								className={styles.dropdown}
								items={tradeStatusOptions}
								header="Trade Status"
								theme="primary"
								name="tradeStatus"
								selectedValue={tradeStatus}
								onSelect={setTradeStatus}
								defaultValue={tradeStatus}
							/>
						</div>

						<div>
							<label htmlFor="aitStatus" className={styles.filterLabel}>
								AIT Status
							</label>
							<Dropdown
								className={styles.dropdown}
								items={aitStatusOptions}
								header="AIT Status"
								theme="primary"
								name="aitStatus"
								onSelect={setAitStatus}
								selectedValue={aitStatus}
								defaultValue={aitStatus}
							/>
						</div>
					</div>
					<footer className={styles.footer}>
						<OutlineButton
							className={styles.button}
							onClick={() => {
								setTradeStatus("all");
								setAitStatus("all");
								onApply("all", "all");
								setIsOpen(false);
							}}
						>
							Clear
						</OutlineButton>
						<PrimaryButton
							className={styles.button}
							onClick={() => {
								onApply(tradeStatus, aitStatus);
								setIsOpen(false);
							}}
						>
							Apply
						</PrimaryButton>
					</footer>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};
