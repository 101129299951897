import { Button } from "@app/controls/button";
import { Dropdown } from "@app/controls/dropdown";
import { useState } from "react";
import { aitStatusOptions, tradeStatusOptions } from "../filter-options";
import { OutlineButton } from "../outline-button";
import styles from "./index.module.css";

export const FiltersPanel = ({
	activeTradeStatus,
	activeAitStatus,
	onApply,
}: {
	activeTradeStatus: string;
	activeAitStatus: string;
	onApply: (tradeStatus: string, aitStatus: string) => void;
}) => {
	const [tradeStatus, setTradeStatus] = useState(activeTradeStatus);
	const [aitStatus, setAitStatus] = useState(activeAitStatus);

	const showClearAll = tradeStatus !== "all" || aitStatus !== "all";
	return (
		<div className={styles.filtersContainer}>
			<p className={styles.filterByTitle}>Filter by</p>
			<div className={styles.filters}>
				<div className={styles.filter}>
					<label htmlFor="tradeStatus" className={styles.filterLabel}>
						Trade Status
					</label>
					<Dropdown
						className={styles.dropdown}
						items={tradeStatusOptions}
						header="Trade Status"
						theme="primary"
						name="tradeStatus"
						selectedValue={tradeStatus}
						onSelect={setTradeStatus}
						defaultValue={tradeStatus}
					/>
				</div>
				<div className={styles.filter}>
					<label htmlFor="aitStatus" className={styles.filterLabel}>
						AIT Status
					</label>
					<Dropdown
						className={styles.dropdown}
						items={aitStatusOptions}
						header="AIT Status"
						theme="primary"
						name="aitStatus"
						onSelect={setAitStatus}
						selectedValue={aitStatus}
						defaultValue={aitStatus}
					/>
				</div>
			</div>
			<div className={styles.actions}>
				{showClearAll && (
					<OutlineButton
						className={styles.clearAll}
						onClick={() => {
							setTradeStatus("all");
							setAitStatus("all");
							onApply("all", "all");
						}}
					>
						Clear all
					</OutlineButton>
				)}
				<Button
					className={styles.applyButton}
					type="button"
					theme="primary"
					onClick={() => onApply(tradeStatus, aitStatus)}
				>
					Apply
				</Button>
			</div>
		</div>
	);
};
