import { type Country, countrySouthAfrica } from "@app/constants/country";
import { paths } from "@app/constants/paths";
import { type Province, provinceOther } from "@app/constants/province";
import type { Client, ReferrerResponse } from "@app/entities";
import {
	useCreateClient,
	useGetClient,
	useGetClientSummaries,
	useGetReferrers,
} from "@app/helpers";
import type { RootState } from "@app/redux";
import type { MappedReasons } from "@app/services";
import { isArray } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import type { DropdownFieldKey } from "./models/dropdown-fields";
import type { DropdownStateKey } from "./models/dropdown-state";
import type { FormErrors, FormValues } from "./models/form";
import type { State } from "./models/state";
import { NewClientModalView } from "./new-client-modal-view";

const TOTAL_STEPS = 2;
const isNewClientDisclaimerEnabled = true;

export const NewClientModal = (props: {
	open: boolean;
	errors?: string[];
	onClose: (isSubmission?: boolean) => void;
}) => {
	const { data: validReferralCode } = useSWR<{
		has_valid_referral_code: boolean;
	}>("auth/users/has_valid_referral_code/");
	const { clients } = useSelector((state: RootState) => state.clients);
	const defaultState: State = {
		form: {},
		referrerOptions: [],
		showOtherProvinceFields: undefined,
		showOtherPostalProvinceFields: undefined,
		showPostalAddressFields: false,
		showHeardAboutUsFields: false,
		disclaimerProceed: !isNewClientDisclaimerEnabled,
		step: 0,
		validate: false,
		showWizard: !isNewClientDisclaimerEnabled,
		showWelcome: true,
	};

	const [createClient] = useCreateClient();
	const [getClientSummaries] = useGetClientSummaries();
	const navigate = useNavigate();
	const [getReferrers] = useGetReferrers();
	const [getClient] = useGetClient();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [state, setState] = React.useState<State>(defaultState);
	const [mappedErrors, setMappedErrors] = React.useState<
		MappedReasons | undefined
	>(undefined);

	const [firstClientDisclaimed, setFirstClientDisclaimed] =
		React.useState<boolean>(clients.length > 1);

	const isComplete = (step: number) => step >= TOTAL_STEPS;

	const setReferrerOptions = (response?: string[] | ReferrerResponse[]) => {
		if (response && response.length > 0 && typeof response[0] !== "string") {
			const unmappedOptions = response as ReferrerResponse[];
			if (unmappedOptions) {
				setState({ ...state, referrerOptions: unmappedOptions });
			}
		}
	};

	const mapFormValuesToClient = (values: FormValues) => {
		let referredByOption = undefined;

		if (typeof values.referredBy === "string") {
			referredByOption = state.referrerOptions.find((value) => {
				return String(value.id) === values.referredBy;
			});
		}

		const postalDetails = {
			postalAddress: values.postalAddress as string,
			postalSuburb: values.postalSuburb as string,
			postalCity: values.postalCity as string,
			postalCountry: values.postalCountry as string,
			postalPostalCode: values.postalPostalCode as string,
			postalProvince: values.postalProvince as string,
			postalProvinceOther: (state.showOtherPostalProvinceFields
				? values.postalProvinceOther
				: undefined) as string,
		};

		return {
			firstName: values.firstName as string,
			middleName: values.middleName as string,
			lastName: values.lastName as string,
			contactNumber: values.contactNumber as string,
			initialInvestment: values.initialInvestment as string,
			ageAndResidency: values.ageAndResidency as boolean,
			sourceOfFunds: values.sourceOfFunds as boolean,
			idNumber: values.idNumber as string,
			taxNumber: values.taxNumber as string,
			residentialAddress: values.residentialAddress as string,
			residentialSuburb: values.residentialSuburb as string,
			residentialCity: values.residentialCity as string,
			residentialCountry: values.residentialCountry as string,
			residentialPostalCode: values.residentialPostalCode as string,
			residentialProvince: values.residentialProvince as string,
			residentialProvinceOther: (state.showOtherProvinceFields
				? values.residentialProvinceOther
				: undefined) as string,
			isPostalSameAsResidentialAddress:
				values.isPostalSameAsResidentialAddress as string,
			...(state.showPostalAddressFields ? postalDetails : {}),
			heardAboutUs: values.heardAboutUs as string,
			heardAboutUsDetails: values.heardAboutUsDetails as string,
			acceptedTerms: values.acceptedTerms as boolean,
			referredBy: values.referredBy as string,
			referredByDetails: (referredByOption !== undefined
				? referredByOption.requiresDetail
					? values.referredByDetails
					: undefined
				: undefined) as string,
			secondaryEmail: values.secondaryEmail as string,
		} as Client;
	};

	const onSubmit = (values: FormValues) => {
		const newState: State = {
			...state,
			form: {
				...state.form,
				...values,
			},
		};

		if (isComplete(newState.step + 1)) {
			setIsSubmitting(true);
			createClient(
				mapFormValuesToClient(newState.form),
				(response, mappedReasons) => {
					setIsSubmitting(false);

					if (response) {
						const step1fields = [
							"firstName",
							"middleName",
							"lastName",
							"contactNumber",
							"initialInvestment",
							"secondaryEmail",
							"ageAndResidency",
							"notUSCitizen",
							"sourceOfFunds",
						];

						if (mappedReasons) {
							for (const key of step1fields) {
								if ((mappedReasons[key] || []).length > 0) {
									setState({ ...newState, step: 0 });
								}
							}
						}

						setMappedErrors(mappedReasons);
						return;
					}
					newState.step += 1;

					getClientSummaries(undefined, undefined, (response) => {
						if (clients.length === 0 && response && !isArray(response)) {
							getClient(response.clients[0].id);
						}
					});

					setFirstClientDisclaimed(true);
					setState(newState);

					if (clients.length > 0) {
						navigate(paths.clients);
					}

					props.onClose(true);
				},
			);
		} else {
			newState.step += 1;
			newState.validate = false;
		}
		setState(newState);
	};

	const onValidate = (errors: FormErrors, submit: () => void) => {
		if (Object.keys(errors).length > 0) {
			setState({ ...state, validate: true });
			return;
		}

		submit();
	};

	const onClearErrors = (name?: string) => {
		if (name) {
			const newMappedErrors = { ...mappedErrors };
			newMappedErrors[name] = undefined;
			setMappedErrors(newMappedErrors);
		} else setMappedErrors(undefined);
	};

	const onClose = () => {
		const newState = {
			...defaultState,
			referrerOptions: state.referrerOptions,
		};

		if (clients.length > 0) {
			newState.disclaimerProceed = state.disclaimerProceed;
			props.onClose();
		}

		setState(newState);
		onClearErrors();
	};

	const onDropdownSelect = (name: DropdownFieldKey, value: string) => {
		let dropdownStateKey: DropdownStateKey = "showOtherProvinceFields";
		let dropdownFlag = false;
		let residentialCountry = state.residentialCountry;
		let postalCountry = state.postalCountry;
		let residentialProvince = state.residentialProvince;
		let postalProvince = state.postalProvince;

		switch (name) {
			case "residentialProvince":
				dropdownStateKey = "showOtherProvinceFields";
				residentialProvince = value as Province;
				dropdownFlag = value === provinceOther;
				break;
			case "postalProvince":
				dropdownStateKey = "showOtherPostalProvinceFields";
				postalProvince = value as Province;
				dropdownFlag = value === provinceOther;
				break;
			case "residentialCountry":
				dropdownStateKey = "showOtherProvinceFields";
				dropdownFlag = value !== countrySouthAfrica;
				residentialCountry = value as Country;

				if (residentialProvince === provinceOther)
					residentialProvince = undefined;
				break;
			case "postalCountry":
				dropdownStateKey = "showOtherPostalProvinceFields";
				dropdownFlag = value !== countrySouthAfrica;
				postalCountry = value as Country;

				if (postalProvince === provinceOther) postalProvince = undefined;
				break;
			case "referredBy":
				dropdownStateKey = "showHeardAboutUsFields";
				dropdownFlag =
					state.referrerOptions.find((option) => {
						if (option.requiresDetail && String(option.id) === value) {
							return option;
						}
					}) !== undefined;
				break;
			case "isPostalSameAsResidentialAddress":
				dropdownStateKey = "showPostalAddressFields";
				dropdownFlag = value === "no";
				break;
		}

		setState({
			...state,
			[dropdownStateKey]: dropdownFlag,
			residentialCountry: residentialCountry,
			postalCountry: postalCountry,
			residentialProvince: residentialProvince,
			postalProvince: postalProvince,
		});

		onClearErrors(name);
	};

	const onResetWizard = () => {
		setState({ ...defaultState, referrerOptions: state.referrerOptions });
		onClearErrors();
	};

	const onDisclaimerProceed = () => {
		setState({
			...state,
			disclaimerProceed: true,
			showWizard: true,
			showWelcome: false,
		});
	};

	const onShowWizard = () => {
		if (state.disclaimerProceed || firstClientDisclaimed)
			setState({ ...state, showWizard: true, showWelcome: false });
	};

	const onBack = (values?: FormValues) => {
		const newState = { ...state };
		newState.step -= 1;
		if (values) newState.form = values;
		if (newState.step < 0) newState.step = 0;
		setState(newState);
	};

	useEffect(() => {
		getReferrers(setReferrerOptions);
	}, []);

	return (
		<NewClientModalView
			{...props}
			clients={clients}
			defaultValues={state.form}
			firstClientDisclaimed={firstClientDisclaimed}
			mappedErrors={mappedErrors}
			referrerOptions={state.referrerOptions}
			step={state.step}
			totalSteps={TOTAL_STEPS}
			isValidReferralCode={validReferralCode?.has_valid_referral_code ?? false}
			residentialCountry={state.residentialCountry}
			postalCountry={state.postalCountry}
			residentialProvince={state.residentialProvince}
			postalProvince={state.postalProvince}
			showOtherProvinceFields={state.showOtherProvinceFields}
			showOtherPostalProvinceFields={state.showOtherPostalProvinceFields}
			showPostalAddressFields={state.showPostalAddressFields ?? false}
			showHeardAboutUsFields={state.showHeardAboutUsFields ?? false}
			showDisclaimer={!state.disclaimerProceed}
			showWizard={clients.length > 1 || state.showWizard}
			showWelcome={clients.length === 0 && state.showWelcome}
			validate={state.validate}
			onClearErrors={onClearErrors}
			onDisclaimerProceed={onDisclaimerProceed}
			onSubmit={onSubmit}
			onClose={onClose}
			isSubmitting={isSubmitting}
			onDropdownSelect={onDropdownSelect}
			onValidate={onValidate}
			onResetWizard={onResetWizard}
			onShowWizard={onShowWizard}
			onBack={onBack}
		/>
	);
};
