import { Popover } from "@app/components/popovers/popover";
import React, { type CSSProperties } from "react";
import "./bar-graph.css";
import { FaInfoCircle } from "react-icons/fa";
import type { ViewProperties } from "./properties";

import { useMediaQuery } from "@app/hooks/use-media-query";
import styles from "./bar-graph-view.module.css";

export const BarGraphView = React.memo((props: ViewProperties) => {
	const isMobile = useMediaQuery();
	const getPopoverContent = (
		totalAmount: number,
		percentages?: {
			first: number;
			second: number;
			third: number;
		},
	) => {
		let firstHovered = false;
		let secondHovered = false;
		let thirdHovered = false;

		if (props.mouseHoverPositionXAxisState && percentages) {
			if (props.mouseHoverPositionXAxisState <= percentages.first)
				firstHovered = true;
			else if (props.mouseHoverPositionXAxisState <= percentages.second)
				secondHovered = true;
			else if (props.mouseHoverPositionXAxisState <= percentages.third)
				thirdHovered = true;
		}

		if (props.details) {
			const sortedDetails = props.details.sort((a, b) => a.order - b.order);
			const heading = props.allowance?.heading;
			const rows = sortedDetails
				.filter((_, index) => index < props.tooltipRowsToShow)
				.map((detail, index) => ({
					label: detail.label,
					amount: detail.amount,
					colorClass: detail.colourClass,
					description: detail.description,
					date: detail.date,
					hovered:
						index === 0
							? firstHovered
							: index === 1
								? secondHovered
								: thirdHovered,
				}));

			return (
				<div
					className={`${styles.popover} font-primary-regular leading-5 -m-2.5 z-10`}
				>
					<div className={styles.heading}>{heading}</div>
					<div className={popoverRowsContainerStyle}>
						{rows.map((row, index) => (
							<div key={row.amount + row.label}>
								<div
									className={[
										row.hovered ? styles.rowHighlighted : "",
										styles.rowContainer,
									].join(" ")}
								>
									<div className={styles.rowContent}>
										<div className={styles.rowContentLeft}>
											<span className={row.colorClass} />
											<span className={styles.label}>{row.label}</span>
											{row.date && (
												<span className={styles.appliedDate}>
													Applied {row.date}
												</span>
											)}
										</div>
										<div className={styles.amount}>
											{props.getFormattedFigures([row.amount])}
										</div>
									</div>

									{row.description && (
										<div className={styles.description}>{row.description}</div>
									)}
								</div>

								{index !== rows.length - 1 && (
									<hr className="block h-px bar-graph-popover-row" />
								)}
							</div>
						))}
						{props.showTooltipTotal && (
							<>
								<hr className="block h-px bar-graph-popover-row" />
								<div className={styles.remaining}>
									<span>Total Remaining</span>
									<span className={styles.amount}>
										{props.getFormattedFigures([totalAmount])}
									</span>
								</div>
							</>
						)}
					</div>
				</div>
			);
		}
	};

	const getBarGraphContent = (
		totalAmount: number,
		firstWidthPercent: number,
		secondWidthPercent: number,
		thirdWidthPercent: number,
		usedWidthPercent: number,
		figuresFormatted: string,
	) => {
		return (
			<Popover
				toggleClassName="w-full"
				toggleContent={
					<div
						className={barStyle}
						ref={props.popoverContainer}
						onMouseMove={props.onBarGraphMouseMove}
					>
						<div className={barTextStyle}>{figuresFormatted}</div>
						<div
							className={barFillStyle}
							style={
								{
									"--first-width-percentage": `${firstWidthPercent}%`,
									"--second-width-percentage": `${secondWidthPercent}%`,
									"--third-width-percentage": `${thirdWidthPercent}%`,
									"--used-width-percentage": `${usedWidthPercent}%`,
								} as CSSProperties
							}
						>
							<div className={"leading-10 inner"}> </div>
						</div>
					</div>
				}
				popperContent={getPopoverContent(totalAmount, {
					first: firstWidthPercent,
					second: secondWidthPercent,
					third: thirdWidthPercent,
				})}
				showOnHover={!isMobile}
				placement={"bottom-start"}
				flip={false}
				offset={({ popper, reference }) => {
					return [(reference.width - popper.width) / 2, reference.height / 2];
				}}
			/>
		);
	};

	const getBarGraph = () => {
		if (!props.allowance || !props.details) {
			return undefined;
		}

		const details = props.details.sort((a, b) => a.order - b.order);

		const firstValue = details[0] ? details[0].amount : 0;
		const secondValue = details[1] ? details[1].amount : 0;
		const thirdValue = details[2] ? details[2].amount : 0;

		const totalAmount = props.totalAmount;

		const usedWidthPercent = Math.max(
			0,
			Math.min(100, (totalAmount / props.allowance.valueUpper) * 100),
		);

		const firstWidthPercent = props.getDetailWidthPercentages(
			totalAmount,
			firstValue,
			usedWidthPercent,
		);

		const secondWidthPercent =
			props.getDetailWidthPercentages(
				totalAmount,
				secondValue,
				usedWidthPercent,
			) + firstWidthPercent;

		const thirdWidthPercent =
			props.getDetailWidthPercentages(
				totalAmount,
				thirdValue,
				usedWidthPercent,
			) + secondWidthPercent;

		const formattedFigures = props.getFormattedFigures([
			totalAmount,
			props.allowance.valueUpper,
		]);
		return (
			<div className={props.addZIndex ? "details-container" : ""}>
				<div className={tooltipContainerStyle}>
					<div className={subheadingStyle} ref={props.headerContainer}>
						{props.allowance.heading}
					</div>

					<div className={tooltipStyle}>
						<Popover
							toggleContent={
								<FaInfoCircle
									className={"opacity-50 withdraw-icon"}
									size="18px"
								/>
							}
							popperContent={getPopoverContent(totalAmount)}
							showOnHover={!isMobile}
							placement={"bottom"}
							flip={false}
							offset={({ reference }) => {
								if (
									!props.popoverContainer.current ||
									!props.headerContainer.current
								)
									return [0, 0];

								const target = props.popoverContainer.current.clientWidth / 2;

								return [
									target -
										props.headerContainer.current.clientWidth -
										reference.width +
										1,
									reference.height +
										props.popoverContainer.current.clientHeight +
										(props.mobile ? 12 : 14),
								];
							}}
						/>
					</div>
				</div>
				{getBarGraphContent(
					totalAmount,
					firstWidthPercent,
					secondWidthPercent,
					thirdWidthPercent,
					usedWidthPercent,
					formattedFigures,
				)}
			</div>
		);
	};

	const barFillStyle = [
		"h-10",
		"text-transparent",
		`bar-graph-fill-${props.graphFillClassNumber}-values`,
	].join(" ");

	const barStyle = ["h-10", "bar-graph"].join(" ");

	const barTextStyle = [
		"absolute",
		"leading-10",
		"left-0",
		"text-center",
		"text-sm",
		"w-full",
		"bar-graph-text",
		"z-10",
	].join(" ");

	const subheadingStyle = ["mb-1.5", "mt-2.5", "bar-text"].join(" ");

	const tooltipStyle = ["mt-3.5", "ml-2"].join(" ");

	const tooltipContainerStyle = ["flex", "flex-row"].join(" ");

	const popoverRowsContainerStyle = ["flex", "flex-col"].join(" ");

	return <div>{getBarGraph()}</div>;
});
