import type { ComponentPropsWithoutRef } from "react";

import clsx from "clsx";
import styles from "./index.module.css";

export const OutlineButton = ({
	children,
	className,
	...rest
}: ComponentPropsWithoutRef<"button">) => {
	return (
		<button type="button" {...rest} className={clsx(styles.button, className)}>
			{children}
		</button>
	);
};
