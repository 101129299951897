const toFixedWithoutRounding = (num: number, fixed: number) => {
	const re = new RegExp(`^-?\\d+(?:\\.\\d{0,${fixed || -1}})?`);
	return num.toString().match(re)?.[0] ?? num.toFixed(fixed);
};

const formatNumber = (num: number, roundTo = 2) => {
	const suffixes = ["", "k", "m", "b"];
	let suffixIndex = 0;
	let value = num;

	while (value >= 1000 && suffixIndex < suffixes.length - 1) {
		value /= 1000;
		suffixIndex++;
	}

	let formattedNum: string;

	if (value >= 100 || (value === Math.floor(value) && suffixIndex === 0)) {
		formattedNum = Math.floor(value).toString();
	} else {
		formattedNum = toFixedWithoutRounding(value, roundTo);
		// Remove only trailing zeros after decimal point
		formattedNum = formattedNum.replace(/\.(\d*[1-9])?(0+)$/, (_, p1) =>
			p1 ? `.${p1}` : "",
		);
	}

	// Ensure that we don't have more than 3 significant digits
	if (formattedNum.replace(".", "").length > 4) {
		formattedNum = formattedNum.slice(0, 4).replace(/\.$/, "");
	}

	return `${formattedNum}${suffixes[suffixIndex] ? `${suffixes[suffixIndex]}` : ""}`;
};

interface FormatConfig {
	currencyCode?: string | undefined;
	decimalPlaces?: number | undefined;
	groupSeparator?: string | undefined;
	isPercentage?: boolean | undefined;
	formatUnit?: boolean | undefined;
}

export const formatChartLabel = (
	value: string | number | undefined,
	config: FormatConfig = {
		currencyCode: "",
		decimalPlaces: 0,
		groupSeparator: "",
		isPercentage: false,
		formatUnit: false,
	},
) => {
	if (value === undefined || value === null) {
		return "";
	}

	const numValue = typeof value === "string" ? Number.parseFloat(value) : value;

	if (Number.isNaN(numValue)) {
		return "";
	}

	if (numValue === 0) {
		return `${config.currencyCode}0${config.isPercentage ? "%" : ""}`;
	}

	const formattedValue = formatNumber(numValue);
	return `${config.currencyCode}${formattedValue}${config.isPercentage ? "%" : ""}`;
};
