import { Graphing } from "@app/components/graphing";
import { dateFormats } from "@app/constants/date-formats";
import { Dropdown } from "@app/controls/dropdown";
import type { ArbitragerSpreads, ChartType, DropdownItem } from "@app/entities";
import type { FormatNumberOptions } from "@app/utils/format-number";
import type { Tick, TooltipItem } from "chart.js";
import moment from "moment";
import React, { memo, useMemo } from "react";
import type { ArbitragerPeriod } from "src/constants/arbitrager-period";
import type { xAxisCallbackFunction } from "../graphing/properties";
import maxTicks from "./models/arbitrager-period-ticks";
import type { ViewProperties } from "./properties";
import "./spread-graph-card.css";

const suffixMap = {
	EXCHANGE_RATE: "",
	LOCAL_PRICE: "",
	OFFSHORE_PRICE: "",
	SPREAD: "%",
};

const prefixMap = {
	EXCHANGE_RATE: "R",
	LOCAL_PRICE: "R",
	OFFSHORE_PRICE: "$",
	SPREAD: "",
};

const breakdownOptions: DropdownItem<ArbitragerSpreads>[] = [
	{
		value: "EXCHANGE_RATE",
		label: "Exchange Rate (USD/ZAR)",
	},
	{
		value: "LOCAL_PRICE",
		label: "Local BTC Price",
	},
	{
		value: "OFFSHORE_PRICE",
		label: "Offshore BTC Price",
	},
	{
		value: "SPREAD",
		label: "Spread",
	},
];

export const SpreadGraphCardView = memo((props: ViewProperties) => {
	const colStyle = "flex flex-col";
	const desktopSeparatorStyle =
		"border-b -mx-9 mt-5 hidden lg:flex spread-graph-separator";

	const mainStyle = [
		colStyle,
		"grow py-7 relative spread-graph",
		props.className ?? "",
	]
		.join(" ")
		.trim();

	const rowStyle = "flex flex-row";
	const headerOuterStyle = [
		colStyle,
		"lg:flex-row px-5 lg:px-9 gap-y-5 justify-between lg:pb-5",
	].join(" ");

	const headerInnerStyle = [rowStyle, "items-center pb-6 lg:pb-0"].join(" ");

	const getMaxTicks = () => {
		const period = props.currentPeriod?.days as ArbitragerPeriod;

		if (period) {
			return maxTicks[period];
		}

		return 12;
	};

	const now = useMemo(() => new Date(), []);

	const breakdownFormatOptions = useMemo((): FormatNumberOptions => {
		const prefix = props.breakdown ? prefixMap[props.breakdown] : "";
		const suffix = props.breakdown ? suffixMap[props.breakdown] : "";

		return {
			currencyCode: prefix,
			decimalPlaces: 3,
			groupSeparator: ",",
			isPercentage: suffix === "%",
			formatUnit: !props.isDesktop,
		};
	}, [props.breakdown, props.isDesktop]);

	const periodOptions = React.useMemo(() => {
		if (props.periodSettings) {
			return props.periodSettings.marketChart.periods.map((x, i) => {
				return {
					value: String(i),
					label: x.display,
				};
			});
		}
		return [];
	}, [props.periodSettings]);

	const xAxisCallback: xAxisCallbackFunction = (
		tickValue: string | number,
		index: number,
		ticks: Tick[],
	): string | number | undefined => {
		if (!props.chartLabelsFull) return undefined;

		let result: string | number | undefined = props.chartLabelsFull[index];
		const convertedResult = moment(result).subtract(
			now.getTimezoneOffset(),
			"minutes",
		);

		const hours = +convertedResult.format("H");
		const minutes = +convertedResult.format("m");

		if ((props.currentPeriod?.days as ArbitragerPeriod) === 1) {
			result = props.chartLabels
				? props.chartLabels[index]
				: convertedResult.format(dateFormats.time24HoursMinutes);
		} else if ((props.currentPeriod?.days as ArbitragerPeriod) === 365) {
			result = convertedResult.format(dateFormats.dateShort);
		} else if (convertedResult.isValid()) {
			if (!(hours === 0 && minutes >= 0 && minutes <= 30)) {
				result = undefined;
			} else {
				result = convertedResult.format(dateFormats.dateShort);
			}
		}

		return result;
	};

	return (
		<>
			<div className={mainStyle}>
				<div className={headerOuterStyle}>
					<div className={[colStyle, "w-full"].join(" ")}>
						<div className="block lg:flex flex-row gap-x-2 justify-between">
							<div className={headerInnerStyle}>
								<div className="spread-graph-breakdown mx-2">
									<Dropdown
										className="breakdown font-primary-bold"
										items={breakdownOptions}
										theme="primary"
										selectedValue={props.breakdown}
										onSelect={
											!props.loading ? props.onChangeBreakdown : undefined
										}
									/>
								</div>
							</div>
							<div className="border-b mb-5 -mx-5 lg:hidden spread-graph-separator" />
							<div className="items-end flex-1 justify-start lg:justify-end gap-y-4 mb-12 lg:mb-0 spread-graph-header-action">
								<Dropdown
									className="spread-graph-dropdown mr-auto lg:mr-0"
									items={periodOptions}
									selectedValue={String(props.intervalIndex)}
									theme="primary"
									onSelect={!props.loading ? props.onChange : undefined}
								/>
							</div>
						</div>
						<div className={desktopSeparatorStyle} />
					</div>
				</div>
				<Graphing
					animationDuration={300}
					autoSkip
					className="w-full spread-graph-chart pl-1.5 lg:pl-5 pr-0 lg:pr-9"
					datasets={props.chartData ? [props.chartData] : []}
					labels={props.chartLabels ? props.chartLabels : []}
					type="line"
					loading={props.loading}
					liveTracker
					liveTrackerFormatOptions={breakdownFormatOptions}
					maxTicksLimit={getMaxTicks()}
					pointHitRadius={30}
					xAxisMaxRotation={props.isDesktop ? 35 : 90}
					xAxisMinRotation={props.isDesktop ? 35 : 90}
					yAxisFormatOptions={breakdownFormatOptions}
					onRenderTooltipLabel={(tooltip: TooltipItem<ChartType>) =>
						props.onRenderTooltipLabel(tooltip, breakdownFormatOptions)
					}
					onRenderTooltipTitle={props.onRenderTooltipTitle}
					xAxisCallback={xAxisCallback}
				/>
			</div>
		</>
	);
});
