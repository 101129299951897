import { ClientAccordion } from "@app/components/client-accordion";
import { CustomLoader } from "@app/components/custom-loader";

import { NewClientModal } from "@app/components/modals/new-client-modal";
import { StatusModal } from "@app/components/modals/status-modal";
import { Navbar } from "@app/components/navbar";
import { Popover } from "@app/components/popovers/popover";
import { Status } from "@app/components/status";
import { type Row, Table } from "@app/components/table";
import { paths } from "@app/constants/paths";
import type { TradeStage } from "@app/constants/trade-stage";
import { Button } from "@app/controls/button";
import { Input } from "@app/controls/input";
import { Paginator } from "@app/controls/paginator";
import { useGetClient } from "@app/helpers";
import { useMediaQuery } from "@app/hooks/use-media-query";
import {
	type FormatNumberOptions,
	formatNumber,
} from "@app/utils/format-number";
import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import { FaInfoCircle } from "react-icons/fa";
import { AllowanceUsed } from "./allowance-used";

import "./clients.css";
import { Dropdown } from "@app/controls/dropdown";
import AnimateHeight from "react-animate-height";
import {
	type ClientSummariesResponse,
	useClientSummaries,
} from "../../hooks/use-client-summaries";
import { FiltersModal } from "./filters-modal";
import { FiltersPanel } from "./filters-panel";
import styles from "./index.module.css";
import { OutlineButton } from "./outline-button";
import { SortableHeader } from "./sortable-header";

const DEFAULT_LIMIT = 25;

const renderTooltip = (
	footnote: string,
	styleOverride?: string,
	isDesktop?: boolean,
) => {
	return (
		<Popover
			className={styleOverride}
			toggleContent={
				<FaInfoCircle className="opacity-50 clients-icon" size="16px" />
			}
			popperContent={
				<div
					className="font-primary-regular leading-5 -m-2.5 p-2.5 clients-footnote"
					dangerouslySetInnerHTML={{
						__html: footnote,
					}}
				/>
			}
			showArrow={true}
			showOnHover={isDesktop}
			placement={isDesktop ? "right-start" : "bottom-end"}
			offset={isDesktop ? [8, 16] : [0, 16]}
		/>
	);
};

const getFormattedClientSummary = (
	client: ClientSummariesResponse["results"][number],
	isDesktop?: boolean,
) => {
	const currencyOptions: FormatNumberOptions = {
		currencyCode: "R ",
		decimalPlaces: isDesktop ? 2 : 0,
		groupSeparator: ",",
	};

	const percentageOptions: FormatNumberOptions = {
		decimalPlaces: 2,
		groupSeparator: ",",
		isPercentage: true,
	};

	const defaultValue = isDesktop ? "R 0" : "R 0";

	const fundsAvailable = client.funds_available
		? formatNumber(client.funds_available ?? 0, currencyOptions)
		: defaultValue;

	const fundsLastUpdatedString = client.funds_last_updated?.string;

	const minimumReturn = client.minimum_return
		? formatNumber(client.minimum_return ?? 0, percentageOptions)
		: "- %";

	const allowance =
		(client.allowance_available?.fia ?? 0) +
		(client.allowance_available?.sda ?? 0);

	const allowanceAvailable = allowance
		? formatNumber(allowance, currencyOptions)
		: defaultValue;

	const totalProfit = client.total_profit
		? formatNumber(client.total_profit ?? 0, currencyOptions)
		: defaultValue;

	const tradeStage = (client.trade_status?.position || 0) as TradeStage;
	const tradeStatus = client.trade_status?.label;

	return {
		firstName: client.first_name,
		lastName: client.last_name,
		fundsAvailable: fundsAvailable,
		fundsLastUpdatedString: fundsLastUpdatedString,
		minimumReturn: minimumReturn,
		allowanceAvailable: allowanceAvailable,
		totalProfit: totalProfit,
		tradeStage: tradeStage,
		tradeStatus: tradeStatus,
	};
};

const getStatusPopover = (
	stage: TradeStage,
	status?: string,
	mobile?: boolean,
) => {
	return (
		<>
			<Popover
				className={["w-full", mobile && "text-right"].join(" ")}
				toggleContent={
					<div className="p-4">
						<Status
							className={mobile ? undefined : "border clients-status-bar"}
							stage={stage}
							totalStages={3}
							type={mobile ? "circle" : "bar"}
						/>
					</div>
				}
				popperContent={
					status && (
						<div className="clients-trade-info">
							<span className="font-primary-medium clients-trade-info-inner">
								{status}
							</span>
						</div>
					)
				}
				showArrow={true}
				showOnHover={!mobile}
				placement="left"
			/>
		</>
	);
};

const getTableHeadersContent = ({
	sortKey,
	sortDirection,
	onSort,
}: {
	sortKey: string | undefined;
	sortDirection: "asc" | "desc" | undefined;
	onSort: (sortKey: string) => void;
}): Row => {
	return {
		cells: [
			{
				content: (
					<SortableHeader
						sortKey="name"
						activeSortKey={sortKey}
						activeSortDirection={sortDirection}
						onSort={onSort}
					>
						Client
					</SortableHeader>
				),
			},
			{
				content: (
					<SortableHeader
						sortKey="bank_balance"
						activeSortKey={sortKey}
						activeSortDirection={sortDirection}
						onSort={onSort}
					>
						Balance
					</SortableHeader>
				),
			},
			{
				content: (
					<div className="font-primary-medium clients-table-header">
						Min Return
					</div>
				),
			},
			{
				content: (
					<SortableHeader
						sortKey="allowance_available"
						activeSortKey={sortKey}
						activeSortDirection={sortDirection}
						onSort={onSort}
					>
						Allowance Available
					</SortableHeader>
				),
			},
			{
				content: (
					<div className="font-primary-medium clients-table-header">
						Total Profit
					</div>
				),
			},
			{
				content: (
					<div className="font-primary-medium clients-table-header">
						Trade Status
					</div>
				),
			},
		],
	};
};

const getMinimumReturnContent = (
	minimumReturn: string,
	isDesktop?: boolean,
): ReactNode => {
	const defaultPercent = minimumReturn === "- %";

	return defaultPercent ? (
		<div className={styles.clientsPercentTooltipContainer}>
			<div className={styles.clientsPercent}>{minimumReturn}</div>
			{renderTooltip(
				"A Minimum Return will only show once a trade is loaded or in progress. If you'd like to get a trade loaded, speak to your Relationship Manager.",
				"z-20",
				isDesktop,
			)}
		</div>
	) : (
		minimumReturn
	);
};

const getTableRow = (
	item: ClientSummariesResponse["results"][number],
	onViewClient: (identifier: number) => void,
	isDesktop?: boolean,
): Row => {
	const client = getFormattedClientSummary(item, isDesktop);

	return {
		cells: [
			{
				content: (
					<div className="font-primary-regular inline whitespace-nowrap clients-table-name">
						{`${client.lastName.trim() ? `${client.lastName.trim()}, ` : ""} ${client.firstName.trim()}`.trim()}
					</div>
				),
			},
			{
				content: (
					<div className="flex whitespace-nowrap">
						{client.fundsAvailable}
						{client.fundsLastUpdatedString &&
							renderTooltip(client.fundsLastUpdatedString, "z-30 ml-2")}
					</div>
				),
			},
			{
				content: getMinimumReturnContent(client.minimumReturn, isDesktop),
			},
			{
				className: "whitespace-nowrap",
				content: client.allowanceAvailable,
			},
			{
				className: "whitespace-nowrap",
				content: client.totalProfit,
			},
			{
				content: getStatusPopover(client.tradeStage, client.tradeStatus ?? ""),
			},
		],
		onClick: () => onViewClient(item.id),
	};
};

const Clients = () => {
	const [sortKey, setSortKey] = useState<string | undefined>("name");
	const [sortDirection, setSortDirection] = useState<
		"asc" | "desc" | undefined
	>("asc");
	const [showFilters, setShowFilters] = useState(false);
	const [tradeStatus, setTradeStatus] = useState("all");
	const [aitStatus, setAitStatus] = useState("all");
	const [limit, setLimit] = useState(DEFAULT_LIMIT);
	const [offset, setOffset] = useState(0);
	const isMobile = useMediaQuery();
	const navigate = useNavigate();
	const [getClient] = useGetClient();

	const [showError, setShowError] = useState(false);
	const [showNewClientModal, setShowNewClientModal] = useState(false);
	const [search, setSearchText] = useState<string | undefined>(undefined);
	const [clients, setClients] = useState<
		ClientSummariesResponse["results"] | undefined
	>(undefined);

	const currentPage = offset / limit + 1;

	const { data, isLoading, error, mutate } = useClientSummaries({
		search,
		offset,
		trade_status: tradeStatus === "all" ? undefined : tradeStatus,
		ait_status: aitStatus === "all" ? undefined : aitStatus,
		limit,
		ordering: `${sortDirection === "desc" ? "-" : ""}${sortKey}`,
	});

	useEffect(() => {
		if (data) {
			setClients(data.results ?? []);
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			setShowError(true);
		}
	}, [error]);

	const onToggleNewClientModal = () =>
		setShowNewClientModal(!showNewClientModal);

	const handleNavigatePage = (page: number) =>
		setOffset(page === 0 ? 0 : (page - 1) * limit);

	const handleSearch = (value: string) => {
		setSearchText(value);
		handleNavigatePage(0);
	};

	const onViewClient = (identifier: number) => {
		getClient(identifier, (response) => {
			if (response && !Array.isArray(response)) {
				navigate(paths.dashboard);
			} else {
				setShowError(true);
			}
		});
	};

	const isDesktop = !isMobile;
	const totalPages = data?.count ? Math.ceil(data.count / limit) : 0;

	const appliedFiltersCount =
		(tradeStatus !== "all" ? 1 : 0) + (aitStatus !== "all" ? 1 : 0);

	return (
		<>
			{isLoading && <CustomLoader page />}
			{showNewClientModal && (
				<NewClientModal
					open
					onClose={(isSubmission) => {
						onToggleNewClientModal();
						if (isSubmission) {
							mutate();
						}
					}}
				/>
			)}
			<div className="min-h-screen h-full clients-container">
				<Navbar />
				<div className="flex flex-col gap-y-3 grow mx-6 lg:mx-14 md:gap-6">
					<div className="flex flex-col">
						<header className={styles.heading}>
							<div className={styles.headingInner}>
								<h2 className={styles.headingTitle}>Clients</h2>
								{!isDesktop && (
									<Button
										className="font-primary-medium grow-0 min-w-fit px-4 py-2.5 text-lg lg:text-base w-auto whitespace-nowrap clients-button"
										theme="primary"
										aria-controls="filters"
										onClick={onToggleNewClientModal}
									>
										Add client
									</Button>
								)}
							</div>

							<AllowanceUsed />
						</header>
						<div className="flex flex-row justify-between gap-x-4 items-center">
							<div className="flex flex-row gap-x-2 items-center">
								<Input
									className="font-primary-regular w-full md:w-60 lg:w-80 clients-input"
									debounceTime={500}
									iconRight
									theme="secondary"
									title="Enter a search string"
									type="search"
									placeholder="Search"
									value={search}
									onChange={handleSearch}
									onEnter={handleSearch}
								/>
								{isDesktop && (
									<OutlineButton
										className={styles.filtersButton}
										aria-expanded={showFilters}
										onClick={() => setShowFilters(!showFilters)}
									>
										{showFilters ? "Hide filters" : "Show filters"}
										{appliedFiltersCount > 0 ? ` (${appliedFiltersCount})` : ""}
										<svg
											className={styles.filtersIcon}
											role="presentation"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M5 7.5L10 12.5L15 7.5"
												stroke="currentColor"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</OutlineButton>
								)}
							</div>

							{isDesktop ? (
								<Button
									className="font-primary-medium grow-0 min-w-fit px-4 py-2.5 text-lg lg:text-base w-auto whitespace-nowrap clients-button"
									theme="primary"
									aria-controls="filters"
									onClick={onToggleNewClientModal}
								>
									Add client
								</Button>
							) : (
								<FiltersModal
									activeTradeStatus={tradeStatus}
									activeAitStatus={aitStatus}
									onApply={(newTradeStatus, newAitStatus) => {
										setTradeStatus(newTradeStatus);
										setAitStatus(newAitStatus);
										handleNavigatePage(0);
									}}
								/>
							)}
						</div>
						<AnimateHeight
							id="filters"
							duration={300}
							height={showFilters && isDesktop ? "auto" : 0}
						>
							{showFilters && (
								<FiltersPanel
									activeTradeStatus={tradeStatus}
									activeAitStatus={aitStatus}
									onApply={(newTradeStatus, newAitStatus) => {
										setTradeStatus(newTradeStatus);
										setAitStatus(newAitStatus);
										handleNavigatePage(0);
										setShowFilters(isDesktop);
									}}
								/>
							)}
						</AnimateHeight>
					</div>
					<div>
						<div className="flex flex-col w-full justify-center clients-content-inner">
							{!isLoading && clients?.length === 0 ? (
								<div className="font-primary-regular my-14 opacity-70 text-base text-center">
									No client data to display
								</div>
							) : isDesktop ? (
								<Table
									className="bg-white mb-10 clients-cards-outer"
									headers={getTableHeadersContent({
										sortKey,
										sortDirection,
										onSort: (newSortKey) => {
											if (newSortKey === sortKey) {
												setSortDirection(
													sortDirection === "asc" ? "desc" : "asc",
												);
											} else {
												setSortDirection("asc");
											}
											setSortKey(newSortKey);
										},
									})}
									data={
										clients?.map((client) =>
											getTableRow(client, onViewClient, isDesktop),
										) ?? []
									}
									pagination
									currentPage={currentPage}
									totalPages={totalPages}
									count={data?.count ?? 0}
									blockSize={limit}
									onBlockSizeChange={(newLimit) => {
										setLimit(newLimit);
										handleNavigatePage(0);
									}}
									onNavigatePage={handleNavigatePage}
								/>
							) : (
								<div className="mb-7 clients-cards-outer">
									<div className={styles.clientsCardsHeading}>
										<SortableHeader
											sortKey="name"
											activeSortKey={sortKey}
											activeSortDirection={sortDirection}
											onSort={(newSortKey) => {
												if (newSortKey === sortKey) {
													setSortDirection(
														sortDirection === "asc" ? "desc" : "asc",
													);
												} else {
													setSortDirection("asc");
												}
											}}
										>
											Client
										</SortableHeader>
									</div>
									<div className="flex flex-col clients-cards-inner">
										{data?.results.map((item) => {
											const client = getFormattedClientSummary(item, isDesktop);
											return (
												<div key={client.lastName + client.lastName}>
													<ClientAccordion
														allowanceAvailable={client.allowanceAvailable}
														clientId={item.id}
														clientName={client.firstName}
														clientSurname={client.lastName}
														fundsAvailable={client.fundsAvailable}
														minReturn={getMinimumReturnContent(
															client.minimumReturn,
															isDesktop,
														)}
														statusContent={getStatusPopover(
															client.tradeStage,
															client.tradeStatus,
															true,
														)}
														tooltipContent={renderTooltip(
															"Note that this is an <strong>approximate</strong> balance based on your recent trades. It may be inaccurate if you have recently deposited or withdrawn funds.",
															"z-30",
														)}
														totalProfit={client.totalProfit}
														onViewClient={onViewClient}
													/>
												</div>
											);
										})}
									</div>

									<div className={styles.footer}>
										<Paginator
											currentPage={currentPage}
											showFixedFirstPage
											showFixedLastPage
											totalPages={totalPages}
											onNavigatePage={handleNavigatePage}
										/>

										<p className={styles.countDescription}>
											Showing results {(currentPage - 1) * limit + 1}-
											{Math.min(currentPage * limit, data?.count ?? 0)} of{" "}
											{data?.count ?? 0}
										</p>

										<div className={styles.pageSize}>
											<p>Rows per page:</p>
											<Dropdown
												className={styles.dropdown}
												items={[
													{
														label: "10",
														value: 10,
													},
													{
														label: "25",
														value: 25,
													},
													{
														label: "50",
														value: 50,
													},
													{
														label: "100",
														value: 100,
													},
												]}
												theme="primary"
												name="limit"
												onSelect={setLimit}
												defaultValue={limit}
											/>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			{showError && (
				<StatusModal
					open
					message={error?.data?.detail}
					showCloseButton
					status="ERROR"
					onClose={() => setShowError(false)}
				/>
			)}
		</>
	);
};

export default Clients;
