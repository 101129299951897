import type { ValidationErrors } from "@app/constants/validation-errors";
import type { DropdownItem } from "@app/entities";
import { FormikControlWrapper } from "@app/wrappers/formik-control-wrapper";
import React from "react";
import { DropdownView } from "./dropdown-view";
import type { Properties } from "./properties";

export const Dropdown = (props: Properties) => {
	const getSelectedItem = () => {
		return props.items.find((x) => {
			if (props.selectedValue) return x.value === props.selectedValue;
			return x.value === props.defaultValue;
		});
	};

	const getError = (value?: any): ValidationErrors | undefined => {
		if (props.required) {
			if (typeof value === "boolean") {
				if (value !== true && value !== false) return "required";
			} else if (!value) return "required";
		}
		return;
	};
	// #region

	const [state, setState] = React.useState<{
		selectedItem: DropdownItem<string> | undefined;
		error: string | undefined;
		open: boolean;
	}>({
		selectedItem: getSelectedItem(),
		error: undefined,
		open: false,
	});

	const onItemSelect = (item: DropdownItem<string>) => {
		if (props.validateOnChange && !props.useFormik)
			setState({
				...state,
				selectedItem: item,
				error: getError(item?.value),
			});
		else
			setState({
				...state,
				selectedItem: item,
			});

		if (props.onSelect) props.onSelect(item.value);
	};

	const onToggle = (open: boolean) => {
		let error = state.error;

		if (!open && props.validateOnBlur)
			error = getError(state.selectedItem?.value);

		setState({
			...state,
			error: error,
			open: open,
		});
	};

	const onValidate = (value?: string) => {
		const error = getError(value);

		setState({
			...state,
			error: getError(value),
		});

		return error;
	};

	React.useEffect(() => {
		if (props.selectedValue !== state.selectedItem?.value)
			setState({ ...state, selectedItem: getSelectedItem() });
	}, [props.selectedValue]);

	return (
		<FormikControlWrapper
			name={props.name || "dropdown"}
			value={state.selectedItem?.value}
			error={state.error}
			component={
				<DropdownView
					{...props}
					open={state.open}
					selectedItem={state.selectedItem}
					hasError={state.error !== undefined}
					onItemSelect={onItemSelect}
					onToggle={onToggle}
				/>
			}
			useFormik={props.useFormik}
			validate={onValidate}
			validateOnChange={props.validateOnChange}
			validateOnBlur={props.validateOnBlur}
		/>
	);
};
