import { Popover, Transition } from "@headlessui/react";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import "./popover.css";
import type { ViewProperties } from "./properties";

export const PopoverView = React.memo((props: ViewProperties) => {
	const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
		null,
	);
	const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: props.placement,
		modifiers: [
			{
				name: "flip",
				enabled: props.flip !== undefined ? props.flip : true,
			},
			{
				name: "offset",
				options: {
					offset: props.offset,
				},
			},
		],
	});

	const getContainerClasses = () => {
		const classesToUse = ["popover z-10"];
		if (props.className) classesToUse.push(props.className);
		return classesToUse.join(" ");
	};

	return (
		<Popover className={getContainerClasses()}>
			{({ open }: { open: boolean }) => {
				return (
					<>
						<Popover.Button
							className={["popover-toggle", props.toggleClassName || ""].join(
								" ",
							)}
							ref={(element: HTMLElement | null) =>
								setReferenceElement(element)
							}
							onClick={() => {
								props.onToggle(!props.open);
							}}
							onMouseOver={() => {
								if (props.showOnHover) {
									props.onToggle(true);
								}
							}}
							onMouseLeave={() => {
								if (props.showOnHover) {
									props.onToggle(false);
								}
							}}
						>
							{props.toggleContent}
						</Popover.Button>

						<Transition
							show={props.showOnHover ? props.open : open}
							className="popover-panel relative"
							enter="transition duration-150 ease-out"
							enterFrom="transform opacity-0"
							leave="transition duration-150 ease-out"
							leaveTo="transform opacity-0"
						>
							<Popover.Panel
								static
								className="panel"
								ref={(element: HTMLElement | null) => setPopperElement(element)}
								style={styles.popper}
								{...attributes.popper}
							>
								{props.showArrow && <div className="arrow" data-popper-arrow />}
								{props.popperContent}
							</Popover.Panel>
						</Transition>
					</>
				);
			}}
		</Popover>
	);
});
