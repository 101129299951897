import { Modal } from "@app/components/modals/modal";
import { commonMessages } from "@app/constants/common-messages";
import {
	type ValidationErrors,
	validationErrorOptions,
} from "@app/constants/validation-errors";
import { Button } from "@app/controls/button";
import { Dropdown } from "@app/controls/dropdown";
import { Input } from "@app/controls/input";
import type { AccountType, DropdownItem } from "@app/entities";
import type { RootState } from "@app/redux";
import { Transition } from "@headlessui/react";
import { Formik } from "formik";
import { memo } from "react";
import { useSelector } from "react-redux";
import "./bank-account-modal.css";

import type { WithdrawalAccount } from "@app/entities";
import type { MappedReasons } from "@app/services";
import type { FormErrors, FormValues } from "./types";

export const BankAccountModalView = memo(
	(props: {
		open: boolean;
		errors?: string[];
		onClose: () => void;
		mode: "add" | "edit";
		bankAccount: WithdrawalAccount;
		defaultValues: FormValues;
		mappedErrors?: MappedReasons;
		validate: boolean;
		isSubmitting?: boolean;
		onClearErrors: (name: string) => void;
		onSubmit: (values: FormValues) => void;
		onValidate: (errors: FormErrors, submit: () => void) => void;
		onResetForm: () => void;
	}) => {
		const { language } = useSelector((state: RootState) => state.language);
		const { activeClient } = useSelector((state: RootState) => state.clients);

		const accountTypes: DropdownItem<AccountType>[] = [
			{
				value: "savings",
				label: "Savings Account",
			},
			{
				value: "cheque",
				label: "Current/Cheque Account",
			},
			{
				value: "transmission",
				label: "Transmission Account",
			},
			{
				value: "bond",
				label: "Bond Account",
			},
			{
				value: "credit",
				label: "Credit Card Account",
			},

			{
				value: "subscription",
				label: "Subscription Account",
			},
		];

		const getError = (show: boolean) => {
			const areErrors =
				show ||
				(props.mappedErrors !== undefined &&
					Object.entries(props.mappedErrors).find((x) => x[1] !== undefined) !==
						undefined);

			return (
				<div className="error pb-5 flex justify-center">
					<span>
						<Transition
							show={areErrors}
							enter="transition ease-out duration-300"
							enterFrom="opacity-0 translate-y-5"
							leave="transition ease-out duration-300"
							leaveTo="opacity-0 -translate-y-5"
						>
							<p>There are errors in your submission. Please see above.</p>
						</Transition>
					</span>
				</div>
			);
		};

		const getFieldErrorContent = (fieldName: string, localError?: string) => {
			const errors = getFieldErrors(fieldName, localError);
			const showCondition = isFieldInError(fieldName, localError);

			return (
				<Transition
					show={showCondition}
					enter="transition ease-out duration-300"
					leave="transition east-out duration-300"
					enterFrom="opacity-0 translate-y-5"
					enterTo="opacity-100 translate-y-0"
					leaveTo="opacity-0 -translate-y-5"
				>
					{errors && (
						<div className="flex flex-col gap-y-2">
							<div
								className="font-primary-regular bank-account-error"
								dangerouslySetInnerHTML={{
									__html: `<p>${errors.join("</p><p>")}</p>`,
								}}
							/>
						</div>
					)}
				</Transition>
			);
		};

		const getHeader = () => {
			return (
				<div className="main-heading mb-10">
					<h2 className="mb-5">
						{props.mode === "add" ? "Add Bank Account" : "Edit Bank Account"}
					</h2>
					<div className="heading-underline" />
				</div>
			);
		};

		const isFieldInError = (
			key: string,
			localError?: string,
		): boolean | undefined => {
			if (localError) {
				return true;
			}
			let result = false;
			if (props.mappedErrors)
				result = (props.mappedErrors[key] ?? []).length > 0;
			if (!result) return undefined;
			return result;
		};

		const getFieldErrors = (
			key: string,
			localError?: string,
		): string[] | undefined => {
			if (localError) {
				return [mapControlError(localError)];
			}

			let result: string[] = [];

			if (props.mappedErrors) {
				result = props.mappedErrors[key] ?? [];
			}

			if (result.length > 0) {
				return result;
			}

			return undefined;
		};

		const mapControlError = (error: string) => {
			const validationError = error as ValidationErrors;
			if (validationErrorOptions.includes(validationError)) {
				if (validationError && validationError.length > 0) {
					return commonMessages[language].validationError[validationError];
				}
				return commonMessages[language].validationError.required;
			}
			return commonMessages[language].validationError.required;
		};

		const getForm = () => {
			const defaultValues: FormValues = {
				...props.defaultValues,
			};

			return (
				<Formik initialValues={defaultValues} onSubmit={props.onSubmit}>
					{({ handleSubmit, validateForm, errors }) => {
						return (
							<>
								{getHeader()}
								<div className="flex flex-wrap flex-1 gap-y-4.5 mb-9.5">
									<div className="grid sm:grid-cols-2 w-full gap-x-11 gap-y-4.5">
										<div className={"flex-row"}>
											<h5 className={"font-bold text-md"}>Account Holder *</h5>
											<h5 className={"account-holder-name pt-4 text-lg"}>
												{`${activeClient?.firstName} ${activeClient?.lastName}`}
											</h5>
										</div>

										<div className="grow mt-auto">
											{getFieldErrorContent("bankName", errors.bankName)}
											<Input
												className="w-full lg:flex-1"
												defaultValue={
													defaultValues.bankName as string | undefined
												}
												error={isFieldInError("bankName")}
												maxLength={30}
												name="bankName"
												placeholder="Bank *"
												title="Please enter your bank name"
												required
												type="text"
												scrollDisabled={true}
												useFormik
												validateOnChange={props.validate}
												onChange={() => props.onClearErrors("bankName")}
											/>
										</div>
									</div>
									<div className="grid sm:grid-cols-2 w-full gap-x-11 gap-y-4.5">
										<div className="grow mt-auto">
											{getFieldErrorContent("branchCode", errors.branchCode)}
											<Input
												className="w-full lg:flex-1"
												defaultValue={
													defaultValues.branchCode as string | undefined
												}
												error={isFieldInError("branchCode")}
												maxLength={30}
												name="branchCode"
												placeholder="Branch Code *"
												title="Please enter your branch code"
												useFormik
												required
												onChange={() => props.onClearErrors("branchCode")}
											/>
										</div>
										<div className="grow mt-auto">
											{getFieldErrorContent("accountType", errors.accountType)}
											<Dropdown
												className="w-full lg:flex-1"
												header="Account Type *"
												items={accountTypes}
												name={"accountType"}
												onSelect={() => props.onClearErrors("accountType")}
												required
												theme="secondary"
												useFormik
												validateOnChange={props.validate}
												defaultValue={defaultValues.accountType}
											/>
										</div>
									</div>
									<div className="grid sm:grid-cols-2 w-full gap-x-11 gap-y-4.5">
										<div className="grow mt-auto">
											{getFieldErrorContent(
												"accountNumber",
												errors.accountNumber,
											)}
											<Input
												className="w-full lg:flex-1"
												defaultValue={
													defaultValues.accountNumber as string | undefined
												}
												error={isFieldInError("accountNumber")}
												maxLength={30}
												name="accountNumber"
												placeholder="Account Number *"
												required
												title="Please enter your account number"
												useFormik
												validateOnChange={props.validate}
												onChange={() => props.onClearErrors("accountNumber")}
											/>
										</div>
									</div>
								</div>
								{getError(Object.keys(errors).length > 0)}
								<div className="flex justify-center mx-auto w-full">
									<Button
										className="mx-auto"
										type="button"
										theme="primary"
										disabled={props.isSubmitting}
										onClick={async () =>
											props.onValidate(await validateForm(), handleSubmit)
										}
									>
										Submit
									</Button>
								</div>
							</>
						);
					}}
				</Formik>
			);
		};

		return props.open ? (
			<Modal
				className="bank-account-modal w-full h-full flex flex-col items-center"
				overlayColor="light"
				open
				onClose={props.onClose}
				onCloseTransitionEnd={props.onResetForm}
			>
				<div className="bank-account-container flex flex-col">{getForm()}</div>
			</Modal>
		) : (
			<></>
		);
	},
);
