import downloadHistory from "@app/assets/images/download-history.svg";
import { CustomLoader } from "@app/components/custom-loader";
import { StatusModal } from "@app/components/modals/status-modal";
import { Navbar } from "@app/components/navbar";
import { Sidebar } from "@app/components/sidebar";
import { type Row, Table } from "@app/components/table";
import { TradeHistoryAccordion } from "@app/components/trade-history-accordion";
import { dateFormats } from "@app/constants/date-formats";
import { Button } from "@app/controls/button";
import { Dropdown } from "@app/controls/dropdown";
import { Input } from "@app/controls/input";
import { Paginator } from "@app/controls/paginator";
import type { Cycle } from "@app/entities";
import type { RootState } from "@app/redux";
import {
	type FormatNumberOptions,
	formatNumber,
} from "@app/utils/format-number";
import moment from "moment";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import "./trades.css";
import styles from "./trades-view.module.css";

const messages = {
	en: {
		groupSeparator: ",",
		noResults: "No trade data to display",
		startDate: "Start Date",
		subHeading: "History",
		table: {
			amountInvested: "Amount Invested",
			cycleCode: "Cycle Code",
			date: "Date",
			download: "Download",
			netProfit: "Net Profit",
			netReturn: "Net Return",
			statement: "Statement",
		},
		tooltip: {
			endDate: "Enter the end date for trades",
			startDate: "Enter the start date for trades",
		},
	},
};

const TradesView = React.memo(
	(props: {
		cycles: Cycle[];
		cyclesTotal: number;
		endDate: string;
		error?: string;
		isDesktop?: boolean;
		loadingData?: boolean;
		loadingPage?: boolean;
		page: number;
		pageCount: number;
		showErrors: boolean;
		startDate: string;
		onChangeEndDate: (date: string) => void;
		onChangeStartDate: (date: string) => void;
		onClearErrors: () => void;
		onDownloadHistory: (format: string) => void;
		onDownloadStatement: (identifier: number) => void;
		onNavigatePage: (page: number) => void;
	}) => {
		const { language } = useSelector(
			(rootState: RootState) => rootState.language,
		);

		const downloadFormatItems = useMemo(() => {
			return [
				{
					label: "CSV",
					value: "CSV",
				},
				{
					label: "PDF",
					value: "PDF",
				},
			];
		}, []);

		const contentInnerStyle = [
			styles.card,
			"pt-7",
			"trades-screen-content-inner",
		].join(" ");

		const downloadStyle = ["pl-0", "pr-0", "pb-0", "pt-0"].join(" ");

		const dropdownInnerStyle = [
			"h-10",
			"z-10",
			props.pageCount === 0 ? "trades-screen-disabled" : "",
		].join(" ");

		const formStyle = [
			"flex flex-row",
			"justify-between",
			"gap-x-2",
			"lg:gap-x-5",
			"items-end",
			"pt-5",
			props.cycles.length > 0 && "mb-8",
		].join(" ");

		const mobileCycleCountStyle = [
			"trades-screen-cycle mobile",
			props.cyclesTotal > 0 ? "-my-6" : "my-2",
		].join(" ");

		const getCardContent = () => {
			return props.cycles.length > 0 ? (
				<>
					<div className="border-t border-b font-primary-medium mt-8 px-6 lg:px-7 py-7 text-lg trades-screen-cards-header">
						{messages[language].table.cycleCode}
					</div>
					<div className="flex flex-col trades-screen-cards">
						{props.cycles.map((item) => {
							const cycle = getFormattedCycleSummary(item);

							return (
								<div key={item.id}>
									<TradeHistoryAccordion
										amountInvested={cycle.zarIn}
										netProfit={cycle.clientProfit}
										netReturn={cycle.clientProfitPercentage}
										tradeId={cycle.cycleCode}
										onDownloadStatement={() =>
											props.onDownloadStatement(item.id)
										}
									/>
								</div>
							);
						})}
					</div>
					{props.pageCount > 0 && (
						<div className="flex flex-row justify-center my-7">
							<Paginator
								currentPage={props.page}
								showFixedFirstPage
								showFixedLastPage
								totalPages={props.pageCount}
								onNavigatePage={props.onNavigatePage}
							/>
						</div>
					)}
				</>
			) : (
				!props.loadingData && (
					<div className="font-primary-regular my-14 opacity-70 text-base text-center">
						{messages[language].noResults}
					</div>
				)
			);
		};

		const getCycleCountContent = () => {
			return `(${props.cyclesTotal} cycles)`;
		};

		const getFormattedCycleSummary = (cycle: Cycle): Cycle => {
			const currencyOptions: FormatNumberOptions = {
				currencyCode: "R ",
				decimalPlaces: props.isDesktop ? 2 : 0,
				groupSeparator: messages[language].groupSeparator,
			};

			const percentageOptions: FormatNumberOptions = {
				decimalPlaces: 2,
				groupSeparator: messages[language].groupSeparator,
				isPercentage: true,
			};

			const defaultValue = "R 0";

			const clientProfit =
				+cycle.clientProfit > 0
					? formatNumber(cycle.clientProfit ?? 0, currencyOptions)
					: defaultValue;

			const profitPercent = +cycle.clientProfitPercentage;

			const clientProfitPercentage =
				profitPercent > 0
					? formatNumber(profitPercent * 100, percentageOptions)
					: "0%";

			const zarIn =
				+cycle.zarIn > 0
					? formatNumber(cycle.zarIn ?? 0, currencyOptions)
					: defaultValue;

			return {
				clientProfit,
				clientProfitPercentage,
				cycleCode: cycle.cycleCode,
				id: cycle.id,
				startDate: cycle.startDate,
				zarIn,
			};
		};

		const getPageContent = () => {
			const pageBlockSize = 3;

			return (
				<div className="content flex flex-col min-w-0 gap-y-6 gap-x-10.5 mx-6 lg:mx-14 mb-7 lg:mb-10 mt-7 grow">
					<div className="font-secondary-regular trades-screen-header">
						Trades
					</div>
					<div className={contentInnerStyle}>
						<div className="px-6 lg:px-7">
							<div className="font-primary-bold trades-screen-subheader">
								{messages[language].subHeading}
							</div>
							<div className="flex flex-col font-primary-medium">
								<div className={formStyle}>
									<div className="flex flex-row w-1/2 min-w-0 gap-x-2 grow lg:gap-x-5 shrink trades-screen-input-group">
										<div className="flex flex-col gap-y-2 min-w-0 grow">
											<div className="overflow-hidden whitespace-nowrap trades-screen-label">
												{messages[language].startDate}
											</div>
											<Input
												className="h-10 trades-screen-input"
												theme="secondary"
												title={messages[language].tooltip.startDate}
												type="date"
												value={props.startDate}
												onChange={props.onChangeStartDate}
											/>
										</div>
										<div className="flex flex-col gap-y-2 min-w-0 grow">
											<div className="overflow-hidden whitespace-nowrap trades-screen-label">
												End Date
											</div>
											<Input
												className="h-10 trades-screen-input"
												theme="secondary"
												title={messages[language].tooltip.endDate}
												type="date"
												value={props.endDate}
												onChange={props.onChangeEndDate}
											/>
										</div>
										<div className="hidden self-end mb-2.5 trades-screen-cycle desktop">
											{getCycleCountContent()}
										</div>
									</div>

									<div className="flex flex-row items-center min-w-0 gap-x-l mr-0 shrink-0">
										<Dropdown
											disabled={props.pageCount === 0}
											chevron={
												props.isDesktop ? undefined : (
													<img src={downloadHistory} alt="" />
												)
											}
											className={dropdownInnerStyle}
											theme={props.isDesktop ? "primary" : "navbar"}
											header={props.isDesktop ? "Download History" : ""}
											fixedHeader
											items={downloadFormatItems}
											onSelect={props.onDownloadHistory}
										/>
									</div>
								</div>
								<div className={mobileCycleCountStyle}>
									{getCycleCountContent()}
								</div>
							</div>
						</div>
						{!props.loadingData && props.cycles.length === 0 ? (
							<div className="font-primary-regular my-14 opacity-70 text-base text-center">
								{messages[language].noResults}
							</div>
						) : props.isDesktop ? (
							<Table
								blockSize={pageBlockSize}
								currentPage={props.page}
								data={getTableDataContent()}
								headers={getTableHeadersContent()}
								pagination={props.cycles.length > 0 && props.pageCount > 0}
								totalPages={props.pageCount}
								onNavigatePage={props.onNavigatePage}
							/>
						) : (
							<>{getCardContent()}</>
						)}
					</div>
				</div>
			);
		};

		const getCyclesDownloader = (identifier: number) => (
			<Button
				className={downloadStyle}
				theme="secondary"
				onClick={() => props.onDownloadStatement(identifier)}
			>
				<div className="trades-screen-button">
					{messages[language].table.download}
				</div>
			</Button>
		);

		const getTableRow = (item: Cycle): Row => {
			const cycle = getFormattedCycleSummary(item);

			return {
				cells: [
					{
						content: cycle.cycleCode,
					},
					{
						content: moment(cycle.startDate, dateFormats.iso8601).format(
							dateFormats.reverseIso8601Slash,
						),
					},
					{
						className: "whitespace-nowrap",
						content: cycle.zarIn,
					},
					{
						className: "whitespace-nowrap",
						content: cycle.clientProfit,
					},
					{
						content: cycle.clientProfitPercentage,
					},
					{
						content: getCyclesDownloader(cycle.id),
					},
				],
			} as Row;
		};

		const getTableDataContent = (): Row[] => {
			return props.cycles.map((x) => getTableRow(x));
		};

		const getTableHeadersContent = (): Row => {
			return {
				cells: [
					{
						content: messages[language].table.cycleCode,
					},
					{
						content: messages[language].table.date,
					},
					{
						content: messages[language].table.amountInvested,
					},
					{
						content: messages[language].table.netProfit,
					},
					{
						content: messages[language].table.netReturn,
					},
					{
						content: messages[language].table.statement,
					},
				],
			};
		};

		return (
			<>
				{(props.loadingPage || props.loadingData) && <CustomLoader page />}
				<div className="min-h-screen w-full trades-screen">
					<Navbar />
					<div className="flex">
						<Sidebar />
						{getPageContent()}
					</div>
				</div>
				{props.showErrors && (
					<StatusModal
						open
						message={props.error}
						showCloseButton
						status="ERROR"
						onClose={props.onClearErrors}
					/>
				)}
			</>
		);
	},
);

export { TradesView };
